
.box{
    width: 540px;
    padding:30px;
    border-color: #e0e1e2;
    border-width: 1px;
    border-style:solid;
    top: 50%;
    left:50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.list{
    list-style: None !important;
}
.listItem {
    margin-bottom: 10px !important;
}

.installerLabel {
    background-color: white !important;
    font-size: 1.1rem !important;
}
.installerLabel1{
    background-color: white !important;
    font-size: 1rem !important;
    color: black !important;
}

.installerRequestMsg{
    background-color: white !important;
    font-size: 1.0rem !important;
    font-weight:400 !important;
}

.installerButton {
    position:absolute !important;
    right: 30px !important;
    color: white !important;
    background-color: green !important
}

.logoutButton {
    float: right !important;
    background-color: #89c5f3 !important;
}

.submitButton {
    background-color: #89c5f3 !important;
}

.helpGuideLabel {
    background-color: white !important;
    font-size: 1.3 rem !important;
    font-weight: 400 !important;
    color:black !important;
}
 
.hrefLink {
    color: blue !important; 
    font-weight: 700 !important;
}

.modalHeader {
    text-align: left !important;
}

.modalContent {
    text-align: left !important;
    padding-left: 0 rem !important;
    margin: 0px !important;
    color: black !important;
}

.tableHeader {
    background-color: null !important;
    font-size: 1.2rem !important;
    font-weight: 700;
}

.downloadHeader {
    background-color: null !important;
    font-size: 1.2rem !important;
    font-weight: 700 !important;
}

.latestDownloadHeader {
    background-color: null !important;
    font-size: 1.285rem !important;
    font-weight: 700 !important;
    color: blue !important
}