.postSegmentStyle {
    background-color:#dfffdf !important;
    padding: 2px !important;
    border-style: solid !important;
    border-width: thin !important;
    border-color: green !important;
}

.deleteSegmentStyle {
    background-color:#ffdfdf !important;
    padding: 2px !important;
    border-style: solid !important;
    border-width: thin !important;
    border-color: red !important;
}

.putSegmentStyle {
    background-color:#fff1d7 !important;
    padding: 2px !important;
    border-style: solid !important;
    border-width: thin !important;
    border-color:orange !important;
}

.getSegementStyle {
    background-color: #d2e9ff !important;
    padding: 2px !important;
    border-style: solid !important;
    border-width: thin !important;
    border-color: blue  !important;
}
.methodLabel {
    text-align: center !important;
    font-size: 1.06rem !important;
}

.apiSchemeLabel {
    margin-left:10px !important; 
    font-weight:bold !important;
    color: black !important;
}

.apiDesLabel {
    margin-left:10px !important; 
    color: black !important;
}

.submitBtn{
    background-color: #4990e2 !important;
}