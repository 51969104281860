h1 {
    font-size: 250%;
}

h2 {
    font-size: 200%;
}

h3 {
    font-size: 150%;
}

h4 {
    font-size: 130%;
}

p {
    font-size: 100%;
}