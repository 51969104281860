.infoBox {
    top: 50%;
    left:50%;
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 24px !important;
    font-weight: 500 !important;
}

.errorBox {
    top: 50%;
    left:50%;
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 18px !important;
    font-weight: 500 !important;
}