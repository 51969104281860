body {counter-reset: h3}
  h3 {counter-reset: h4}
  h4 {counter-reset: h5}
  h5 {counter-reset: h6}
  

  h3:before {counter-increment: h3; content: counter(h3) ". "}
  h4:before {counter-increment: h4; content: counter(h3) "." counter(h4) ". "}
  h5:before {counter-increment: h5; content: counter(h3) "." counter(h4) "." counter(h5) ". "}
  h6:before {counter-increment: h6; content: counter(h3) "." counter(h4) "." counter(h5) "." counter(h6) ". "}
  

  h3.nocount:before, h4.nocount:before, h5.nocount:before, h6.nocount:before, h7.nocount:before { content: ""; counter-increment: none } 